<template>
    <div>
        <div class="bgc-img">
            <div class="banner">
                <el-carousel trigger="click" height="100vh" :autoplay="true" :loop="true" :interval="4000" @change="change">
                    <el-carousel-item v-for="(item,index) in bannerList" :key="item">
                        <div class="banner1" v-if="index == 0">
                            <div class="fixed">
                                <div class="shadow-view">
                                    <div class="shadow animate__animated animate__fadeIn animate__delay-3s"> </div>
                                    <div class="triangle animate__animated animate__fadeIn animate__delay-2s"></div>
                                    <div class="text1 animate__animated animate__fadeInLeft"></div>
                                    <div class="text2 animate__animated animate__fadeInRight"></div>
                                    <div class="text3 animate__animated animate__fadeIn animate__delay-4s"></div>
                                </div>
                            </div>
                            <img  src="../assets/banner/banner1.jpg">
                        </div>
                        <div class="banner2" v-if="index == 1">
                            <div class="banner2_fixed">
                                <div class="fixed_1">
                                    <div class="fixed_view">
                                        <div class="fixed_text1   " :class="bannerIndex == 1 || bannerIndex == 2? 'animate__animated animate__zoomInDown animate__delay-02s' : ''"></div>
                                        <div class="fixed_present   " :class="bannerIndex == 1 || bannerIndex == 2  ? 'animate__animated animate__bounceInDown animate__delay-1s' : ''"></div>
                                        <div class="fixed_shadow " :class="bannerIndex == 1 || bannerIndex == 2? 'animate__animated animate__fadeIn animate__delay-1s' : ''"></div>
                                    </div>
                                </div>
                                <div class="fixed_text2" :class="bannerIndex == 1 || bannerIndex == 2? 'animate__animated animate__zoomInDown' : ''"></div>
                                <div class="fixed_img " :class="bannerIndex == 1  || bannerIndex == 2? 'animate__animated animate__slideInDown animate__delay-2s' : ''"></div>
                            </div>
                            <img :src="item.img" alt="">
                        </div>
                        <div class="banner3" v-if="index == 2">
                            <div class="banner3_fixed">
                                <div class="banner3_bg " :class="bannerIndex == 2 ? 'animate__animated animate__fadeIn animate__delay-2s' : ''"></div>
                                <div class="banner3_text1 " :class="bannerIndex == 2? 'animate__animated animate__flipInX animate__delay-02s' : ''"></div>
                                <div class="banner3_text2 " :class="bannerIndex == 2? 'animate__animated animate__bounceInDown animate__delay-02s' : ''"></div>
                                <div class="banner3_text3" :class="bannerIndex == 2? 'animate__animated animate_banner3Text3  animate__delay-1s' : ''">
                                    <div class="banner3_view">
                                        <div class="banner3_text3_bg " :class="bannerIndex == 2? 'animationText3 animate__delay-1s' : ''"></div>
                                    </div>

                                </div>
                            </div>
                            <img :src="item.img">
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
                <div class="cenji">
                    <div class="row">

                        <div class="classify">
                            <div class="classify-view">
                                <img src="../assets/classfiyBg.png" class="clssfiy-img">
                                <div class="Lefteffects "></div>
                                <div class="Lefteffects1 div"></div>
                                <div class="Lefteffects2"></div>
                                <div class="Lefteffects3"></div>
                                <div class="Lefteffects4"></div>
                                <div class="Lefteffects5 div2"></div>
                                <div class="Lefteffects6 div4"></div>
                                <div class="Lefteffects7 div1"></div>
                                <div class="Lefteffects8"></div>
                                <div class="Lefteffects9 div5"></div>
                                <div class="Lefteffects10 div1"></div>
                                <div class="Lefteffects11 div3"></div>
                                <div class="Lefteffects12 div5  "></div>
                                <div class="Lefteffects13 div6"></div>
                                <div class="Lefteffects14"></div>
                                <div class="Lefteffects15 div1 delay-1s"></div>
                                <div class="Lefteffects16 div4"></div>
                                <div class="Lefteffects17 div4 delay-1s"></div>
                                <div class="Lefteffects18 div4 delay-1s"></div>
                                <div class="Lefteffects19 div4 delay-1s"></div>
                                <div class="Lefteffects20 div4 delay-1s"></div>
                                <div class="content">
                                   <div class="content-view sj-view">
                                       <div class="merchant btn-row sj" @click="$router.push('/merchant')">
                                           <div class="sj-img"></div>
                                           <span>我是商家</span>
                                       </div>
                                   </div>
                                    <div class="content-view dr-view">
                                        <div class="expert centre dr" @click="pathFn">
                                            <div class="dr-img"></div>
                                            <span>我是达人</span>
                                        </div>
                                    </div>
                                    <div class="content-view pk-view">
                                        <div class="paikew btn-row pk" @click="$router.push('/login')">
                                            <div class="pk-img"></div>
                                            <span>我是拍客</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <footer class="footer">
            <span v-html="appInfo.copyright"></span>
        </footer>
    </div>
</template>

<script>
    import 'animate.css';
    import api from "../lnit/api";
    export default {
        name: "content",
        data(){
            return{
                i:0,
                i2:0,
                bannerIndex:0,
                appInfo:{},
                bannerList:[
                    {
                        img:require('../assets/banner/banner1.jpg')
                    },
                    {
                        img:require('../assets/banner/banner2.png')
                    },
                    {
                        img:require('../assets/banner/banner3.jpg')
                    }
                ]
            }
        },
        created() {
            console.log(this)
            this.getAppInfoFn()
        },
        methods:{
            getAppInfoFn(){
                this.$get({
                    url:api.app_info
                }).then((res)=>{
                    this.appInfo = res.data.app_info
                })
            },
            pathFn(){
              this.$router.replace('/intelligent')
            },
            change(e){
                if(e == 1){
                    if(this.i == 0){
                        this.i++
                        this.bannerIndex = e
                    }
                }else if (e == 2){
                    if(this.i2 == 0){
                        this.i2++
                        this.bannerIndex = e
                    }
                }


            }
        }
    }
</script>

<style scoped lang="scss">
    /*:root {*/
    /*    --animate-delay: 0.5s;*/
    /*}*/

    .banner3{
        position: fixed;
        width: 100%;
        height: 100%;
        img{
            width: 100%;
            height: 100%;
        }
        .banner3_fixed{
            position: fixed;
            left: 62%;
            top: 40%;
            width: 481px;
            height: 302px;
            transform: translate(-50%,-50%);
            perspective: 1000px;
            .banner3_bg{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                width: 481px;
                height: 302px;
                background: url("../assets/banner/banner3/banner3_bg.png") no-repeat;
                background-size: cover;
            }
            .banner3_text1{
                position: absolute;
                left: -20px;
                top: 61px;
                width: 537px;
                z-index:100;
                height: 72px;
                background: url("../assets/banner/banner3/banner3_text1.png") no-repeat;
                background-size: cover;
            }
            .banner3_text2{
                position: absolute;
                left: 15%;
                top: 150px;
                transform: translateX(-50%);
                width: 339px;
                z-index:100;
                height: 30px;
                background: url("../assets/banner/banner3/banner3_text2.png") no-repeat;
                background-size: cover;
            }
            .banner3_text3{
                transform-style: preserve-3d;
                position: absolute;
                left: 50%;
                top: 212px;
                width: 652px;
                height: 51px;
                background: linear-gradient(78deg, #007FED, #C50D64);
                border-radius: 26px;
                transform: translateX(-50%) translateZ(0px);
                z-index:100;
                background-size: cover;

                .banner3_view{
                    position: relative;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    padding: 0 20px;
                    .banner3_text3_bg{
                        width: 609px;
                        height: 30px;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: url("../assets/banner/banner3/banner3_text3_bg.png") no-repeat;
                        background-size: cover;
                    }
                }

            }
        }
    }
    .animationText3{
        width: 609px;
        white-space: nowrap;
        color: white;
        font-size: 30px;
        overflow: hidden;
        animation: typing 2.5s linear;

    }
    .animate__delay-05s{
        animation-delay: 0.5s!important;
    }
    .animate__delay-1s{
        animation-delay:1s!important;
    }
    @keyframes typing {
        from,
        60%,
        75%,
        90%,
        to {
            -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        from {
            width: 0;
        }
        to {
            width: 609px;
        }
    }
    .animate_banner3Text3{
        animation: banner3Text3 0.5s;
        animation-fill-mode: both;
    }
    @keyframes banner3Text3 {
        from{
            opacity: 0;
            transform: translateX(-50%) translateZ(300px);
        }
        to{
            opacity: 1;
            transform: translateX(-50%) translateZ(0);
        }
    }
    @keyframes text3 {
        from{
            width: 0;
        }
        to{
            width: 609px;
        }
    }
    .banner2{
        position: fixed;
        width: 100%;
        height: 100%;
        img{
            width: 100%;
            height: 100%;
        }
        .banner2_fixed{
            position: fixed;
            left: 50%;
            top: 35%;
            height:400px;
            width: 531px;
            transform: translate(-50%,-50%);
            .fixed_text2{
                position: absolute;
                left: 0;
                top: 135px;
                width: 531px;
                height: 86px;
                background: url("../assets/banner/fixed_text2.png") no-repeat;
                background-size: cover;
            }
            .fixed_img{
                position: absolute;
                left: 22%;
                top: 215px;
                width: 290px;
                height: 120px;
                transform: translateX(-50%);
                background: url("../assets/banner/brokerage.png") no-repeat;
                background-size: cover;
            }
            .fixed_1{
                position: absolute;
                top: 0;
                left: 0;
                .fixed_view{
                    position: relative;
                    width: 350px;
                    height: 127px;
                    .fixed_text1{
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 360px;
                        height: 35px;
                        background: url("../assets/banner/fixed_text1.png") no-repeat;
                        background-size: 352px 35px;
                        z-index:100;
                    }
                    .fixed_present{
                        position: absolute;
                        left: 38%;
                        bottom: 0;
                        width: 84px;
                        height: 71px;
                        transform: translateX(-50%) translateY(5px);
                        background: url("../assets/banner/present.png") no-repeat;
                        background-size: cover;
                        z-index:10;
                    }
                    .fixed_shadow{
                        width: 318px;
                        height: 127px;
                        border-radius: 50%;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        background: url("../assets/banner/fixed_shadow.png") no-repeat;
                        background-size: cover;
                        z-index:1;
                    }
                }
            }
        }
    }

    .my-element {
        display: inline-block;
        margin: 0 0.5rem;

        animation: bounce; /* referring directly to the animation's @keyframe declaration */
        animation-duration: 2s; /* don't forget to set a duration! */
    }
    .div{
        animation:myanimation 4s infinite;
    }

    @keyframes myanimation {
        0% {
            transform: translateX(0px);
        }
        50% {
            transform: translateX(-20px);
        }
        100%{
            transform: translateX(0px);
        }
    }
    .div1{
        animation:myanimation1 4s infinite;
        animation-delay: 0.5s;
    }
    .delay-1s{
        animation-delay: 1s!important;
    }
    @keyframes myanimation1 {
        0% {
            transform: translateY(0px);
        }
        50% {
            transform: translateY(-20px);
        }
        100%{
            transform: translateY(0px);
        }
    }
    .animate__fadeInRight{
        animation-delay: 0.5s !important;
    }
    .animate__fadeInLeft{
        animation-delay: 0.5s !important;
    }
    .animate__delay-2s{
        animation-delay: 1.5s !important;
    }
    .animate__delay-3s{
        animation-delay: 2s !important;
    }
    .animate__delay-4s{
        animation-delay: 2.5s !important;
    }
    .animate__delay-02s{
        animation-delay: 0.5s!important;
    }
    .div2{
        animation:myanimation2 4s infinite;
        animation-delay: 1s;
    }
    @keyframes myanimation2 {
        0% {
            transform: translateY(0px) translateX(0);
        }
        50% {
            transform: translateY(-20px)  translateX(20px);
        }
        100%{
            transform: translateY(0px) translateX(0);
        }
    }
    .div3{
        animation:myanimation3 4s infinite;
    }
    @keyframes myanimation3 {
        0% {
            transform: translateY(0px) ;
        }
        50% {
            transform: translateY(20px) ;
        }
        100%{
            transform: translateY(0px) ;
        }
    }
    .div4{
        animation:myanimation4 4s infinite;
    }
    @keyframes myanimation4 {
        0% {
            transform: translateY(0px) ;
        }
        50% {
            transform: translateY(20px) ;
        }
        100%{
            transform: translateY(0px) ;
        }
    }
    .div5{
        animation:myanimation5 4s infinite;
    }
    @keyframes myanimation5 {
        0% {
            transform: translateY(0px) ;
        }
        50% {
            transform: translateY(20px) ;
        }
        100%{
            transform: translateY(0px) ;
        }
    }
    .div6{
        animation:myanimation6 4s infinite;
    }
    @keyframes myanimation6 {
        0% {
            transform: translateY(0px) translateX(0px) ;
        }
        50% {
            transform:  translateX(-20px) ;
        }
        100%{
            transform: translateY(0px) translateX(0);
        }
    }
    .row{
        position: absolute;
        top:0;
        left:0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
    }
    .Lefteffects1{
        width: 25px;
        height: 25px;
        background: #FFFFFF;
        border: 5px solid rgba(255, 255, 255, 0.58);
        border-radius: 50%;
        position: absolute;
        left: 19px;
        top: 17px;
        box-sizing: border-box;
        background-clip: content-box;
        transition: 0.5s;
        z-index:101;
    }
    .Lefteffects1:hover{
        width: 30px;
        height: 30px;
        top: 5px;
        left: 15px;
    }
    .Lefteffects15{
        width: 25px;
        height: 25px;
        background: #FFFFFF;
        border: 5px solid rgba(255, 255, 255, 0.58);
        border-radius: 50%;
        position: absolute;
        left: -50px;
        top: 17px;
        box-sizing: border-box;
        background-clip: content-box;
        transition: 0.5s;
        z-index:101;
    }
    .Lefteffects15:hover{
        transform: scale(1.2);
        top: 5px;
        left: 15px;
    }
    .Lefteffects2{
        width: 42px;
        height: 42px;
        border-radius: 50%;
        box-sizing: border-box;
        background-color: #fff;
        background-clip: content-box;
        border: 5px solid rgba(255, 255, 255, 0.58);
        position: absolute;
        left: 344px;
        top: 5px;
        transition: 0.5s;
        z-index:101;
    }
    .banner{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
    }
    .Lefteffects2:hover{
        width: 46px;
        height: 46px;
        top: -10px;
        left: 340px;
    }
    .Lefteffects3{
        width: 33px;
        height: 33px;
        border-radius: 50%;
        background-color: #fff;
        box-sizing: border-box;
        background-clip: content-box;
        border: 5px solid rgba(255, 255, 255, 0.58);
        position: absolute;
        left: 598px;
        bottom: 55px;
        transition: 0.5s;
        z-index:101;
    }
    .Lefteffects3:hover{
        width: 37px;
        height: 37px;
        bottom: 65px;
        left: 596px;
    }
    .Lefteffects4{
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background-color: #007FED;
        box-sizing: border-box;
        background-clip: content-box;
        border: 5px solid rgba(255, 255, 255, 0.58);
        position: absolute;
        left: 235px;
        bottom: 55px;
        transition: 0.5s;
        z-index:101;
    }
    .Lefteffects4:hover{
        width: 38px;
        height: 38px;
        left: 233px;
        bottom: 65px;
    }
    .Lefteffects16{
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background-color: #ffffff;
        box-sizing: border-box;
        background-clip: content-box;
        border: 5px solid rgba(255, 255, 255, 0.58);
        position: absolute;
        left: 300px;
        top: -100px;
        transition: 0.5s;
        z-index:101;
    }
    .Lefteffects16:hover{
        width: 25px;
        height: 25px;
        left: 300px;
        top: -130px;
    }
    .Lefteffects17{
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background-color: #ffffff;
        box-sizing: border-box;
        background-clip: content-box;
        border: 5px solid rgba(255, 255, 255, 0.58);
        position: absolute;
        left: 450px;
        top: 100px;
        transition: 0.5s;
        z-index:101;
    }
    .Lefteffects17:hover{
        width: 25px;
        height: 25px;
        left: 450px;
        top: 120px;
    }
    .Lefteffects18{
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background-color: #ffffff;
        box-sizing: border-box;
        background-clip: content-box;
        border: 5px solid rgba(255, 255, 255, 0.58);
        position: absolute;
        left: 620px;
        top:    0px;
        transition: 0.5s;
        z-index:101;
    }
    .Lefteffects18:hover{
        width: 25px;
        height: 25px;
        left: 620px;
        top: 30px;
    }
    .Lefteffects5{
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background-color: #007FED;
        box-sizing: border-box;
        background-clip: content-box;
        border: 5px solid rgba(255, 255, 255, 0.58);
        position: absolute;
        left: 262px;
        top: 27px;
        transition: 0.5s;
        z-index:101;
    }
    .Lefteffects5:hover{
        width: 25px;
        height: 25px;
        left: 260px;
        top: 15px;
    }
    .Lefteffects6{
        width: 19px;
        height: 19px;
        border-radius: 50%;
        box-sizing: border-box;
        background-color: #007FED;
        background-clip: content-box;
        border: 5px solid rgba(255, 255, 255, 0.58);
        position: absolute;
        left: 550px;
        top: 52px;
        transition: 0.5s;
        z-index:101;
    }
    .Lefteffects6:hover{
        width: 23px;
        height: 23px;
        left: 548px;
        top: 40px;
    }
    .Lefteffects7{
        width: 19px;
        height: 19px;
        box-sizing: border-box;
        border-radius: 50%;
        background-color: #007FED;
        background-clip: content-box;
        border: 5px solid rgba(255, 255, 255, 0.58);
        position: absolute;
        right: 137px;
        top: 101px;
        transition: 0.5s;
        z-index:101;
    }
    .Lefteffects7:hover{
        width: 23px;
        height: 23px;
        right: 134px;
        top: 90px;
    }
    .Lefteffects19{
        width: 19px;
        height: 19px;
        box-sizing: border-box;
        border-radius: 50%;
        background-color: #ffffff;
        background-clip: content-box;
        border: 5px solid rgba(255, 255, 255, 0.58);
        position: absolute;
        right: 90px;
        top: 40px;
        transition: 0.5s;
        z-index:101;
    }
    .Lefteffects19:hover{
        width: 23px;
        height: 23px;
        right: 90px;
        top: 40px;
    }
    .Lefteffects20{
        width: 19px;
        height: 19px;
        box-sizing: border-box;
        border-radius: 50%;
        background-color: #ffffff;
        background-clip: content-box;
        border: 5px solid rgba(255, 255, 255, 0.58);
        position: absolute;
        right: 40px;
        top: -40px;
        transition: 0.5s;
        z-index:101;
    }
    .Lefteffects20:hover{
        width: 23px;
        height: 23px;
        right: 40px;
        top: -40px;
    }
    .Lefteffects8{
        width: 43px;
        height: 43px;
        border-radius: 50%;
        box-sizing: border-box;
        background-color: #007FED;
        background-clip: content-box;
        border: 5px solid rgba(255, 255, 255, 0.58);
        position: absolute;
        right: 39px;
        bottom: 70px;
        transition: 0.5s;
        z-index:101;
    }
    .Lefteffects8:hover{
        width: 48px;
        height: 48px;
        right: 37px;
        bottom: 80px;
    }
    .Lefteffects9{
        width: 19px;
        height: 19px;
        border-radius: 50%;
        box-sizing: border-box;
        background-color: #007FED;
        background-clip: content-box;
        border: 5px solid rgba(255, 255, 255, 0.58);
        position: absolute;
        right: 45px;
        top: 6px;
        transition: 0.5s;
        z-index:101;
    }
    .Lefteffects9:hover{
        width: 23px;
        height: 23px;
        right: 42px;
        top: -5px;
    }
    .Lefteffects10{
        width: 27px;
        height: 27px;
        border-radius: 50%;
        box-sizing: border-box;
        background-color: #007FED;
        background-clip: content-box;
        border: 5px solid rgba(255, 255, 255, 0.58);
        position: absolute;
        left: 63px;
        top: -54px;
        transition: 0.5s;
        z-index:101;
    }
    .Lefteffects10:hover{
        width: 32px;
        height: 32px;
        left: 60px;
        top: -65px;
    }
    .Lefteffects11{
        width: 25px;
        height: 25px;
        border-radius: 50%;
        box-sizing: border-box;
        background-color: #007FED;
        background-clip: content-box;
        border: 5px solid rgba(255, 255, 255, 0.58);
        position: absolute;
        left: 362px;
        top: -44px;
        transition: 0.5s;
        z-index:101;
    }
    .Lefteffects11:hover{
        width: 30px;
        height: 30px;
        left: 360px;
        top: -55px;
    }
    .Lefteffects12{
        width: 25px;
        height: 25px;
        box-sizing: border-box;
        border-radius: 50%;
        background-color: #007FED;
        background-clip: content-box;
        border: 5px solid rgba(255, 255, 255, 0.58);
        position: absolute;
        right: 291px;
        top: -41px;
        transition: 0.5s;
        z-index:101;
    }
    .Lefteffects12:hover{
        width: 30px;
        height: 30px;
        right: 288px;
        top: -55px;
    }
    .Lefteffects13{
        width: 34px;
        height: 34px;
        border-radius: 50%;
        box-sizing: border-box;
        background-color: #fff;
        background-clip: content-box;
        border: 5px solid rgba(255, 255, 255, 0.58);
        position: absolute;
        top: 50px;
        right: -54px;
        transition: 0.5s;
        z-index:101;
    }
    .Lefteffects13:hover{
        width: 40px;
        height: 40px;
        right: -56px;
        top: 30px;
    }
    .Lefteffects14{
        width: 54px;
        height: 54px;
        border-radius: 50%;
        box-sizing: border-box;
        background-color: #007FED;
        background-clip: content-box;
        border: 5px solid rgba(255, 255, 255, 0.58);
        position: absolute;
        top: 50px;
        right: 242px;
        transition: 0.5s;
        z-index:101;
    }
    .Lefteffects14:hover{
        transform: scale(1.2);
        right: 242px;
        top: 30px;
    }
    .text-img{
        position: absolute;
        top: 200px;
        left: 50%;
        transform:translateX(-50%);
        height: 120px;
        z-index: 1000;
    }
    .bgc-img{
        position: relative;
        background-size: cover;
        min-height: calc(100vh - 60px);
        background-position: 0 0;
        min-width: 1200px;
        max-width: 100%;
        overflow: hidden;
    }
    .cenji{
        /*position: absolute;*/
        /*width: 100%;*/
        /*min-height: calc(100vh - 80px);*/
        /*background-color: rgba(0,0,0,.4);*/
        /*z-index: 10;*/
        width: 100%;
    }
    .classify{
        width: 959px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        z-index: 1000;
    }
    .classify-view{
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .clssfiy-img{
            width: 959px;
            height: 204px;
        }

    }
    .Lefteffects:hover{
        bottom: 90px;
        width: 45px;
        height: 45px;
        left: 75px;
    }
    .Lefteffects{
        position: absolute;
        left: 77px;
        bottom: 80px;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background-color: #fff;
        transition: 0.5s;
        background-clip: content-box;
        box-sizing: border-box;
        border: 5px solid rgba(255, 255, 255, 0.58);
        display: flex;
        align-items: center;
        justify-content: center;
        /*.inner{*/
        /*    width: 42px;*/
        /*    height: 42px;*/
        /*    border-radius: 50%;*/
        /*    background-color: #fff;*/
        /*}*/
    }
    .pk-view:hover{
        width: 166px;
        height: 166px;
        top: -50px;
        background-color: rgba(225,225,225,0.62);
        box-shadow: 0px 4px 54px 0px #00318C;
        transform: translate(5px,-20px);

    }
    .pk-view:hover .pk{
        width: 145px;
        height: 145px;
        border: 5px solid rgba(225,225,225,0.62);
        box-shadow: 0px 4px 54px 0px #00318C;
        background-color: #007FED;
        border: 3px solid #2A00FF;
        color: white;

    }

    .pk-img{
        width: 37px;
        height: 36px;
        margin-bottom: 11px;
        background: url("../assets/pk-icon.png") no-repeat;
        background-size: 37px;
    }
    .pk-view:hover .pk-img{
        background: url("../assets/pk.png") no-repeat;
        background-size: cover;
    }
    .btn-row{
        display: flex;
        border-radius: 50%;
        background-color: #fff;
        border: 5px solid #007FED;
        color: white;
        width: 138px;
        transition: 0.9s;
        height: 138px;
        cursor: pointer;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        box-sizing: border-box;
        color: #007FED;
    }
    /*.centre:hover{*/
    /*    width: 145px;*/
    /*    height: 145px;*/
    /*    border: 5px solid rgba(225,225,225,0.62);*/
    /*    box-shadow: 0px 4px 54px 0px #00318C;*/
    /*    background-color: #007FED;*/
    /*    border: 5px solid #FFD200;*/


    /*}*/
    .banner1{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        img{
            position: absolute;
            width: 100%;
            height: 100%;
        }
        .fixed{
            position: absolute;
            left: 45%;
            top: 88px;
            transform: translateX(-50%) scale(0.8);
            z-index:100;
            width: 947px;
            height: 420px;

        }
    }
    .shadow{
        position: absolute;
        left: 0;
        top: 0;
        width: 947px;
        height: 420px;
        background: url("../assets/banner/banner1-bg4.png") no-repeat;
        background-size: 947px 420px;
    }
    .shadow-view{
        position: relative;
        width: 947px;
        height: 420px;
    }
    .triangle{
        width: 307px;
        height: 301px;
        position: absolute;
        left: 191px;
        bottom: 0;
        background: url("../assets/banner/triangle.png") no-repeat;
        background-size: cover;
        z-index:1000;
    }
    .text1{
        width: 385px;
        height: 132px;
        position: absolute;
        left: 88px;
        bottom: 142px;
        background: url("../assets/banner/text1.png") no-repeat;
        background-size: cover;
        z-index:1000;
    }
    .text2{
        width: 750px;
        height: 128px;
        position: absolute;
        left: 105px;
        bottom: 65px;
        z-index: 1000;
        background: url("../assets/banner/text2.png") no-repeat;
        background-size: cover;
    }
    .text3{
        width: 500px;
        height: 18px;
        position: absolute;
        left: 135px;
        bottom: 52px;
        background: url("../assets/banner/text3.png") no-repeat;
        background-size: cover;
    }
    .centre{
        width: 138px;
        transition: 0.9s;
        height: 138px;
        cursor: pointer;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #007FED;
        border-radius: 50%;
        background-color: #fff;
        border: 5px solid #007FED;
        box-sizing: border-box;


    }
    .dr-view{
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-40%);
    }
    .dr-img{
        width: 37px;
        height: 36px;
        margin-bottom: 11px;
        background: url("../assets/dr-icon.png") no-repeat;
        background-size: 37px;
    }
    .dr-view:hover .dr-img{
        background: url("../assets/dr.png") no-repeat;
        background-size: cover;
    }
    .dr-view:hover{
        width: 166px;
        height: 166px;
        top: -30px;
        background-color: rgba(225,225,225,0.62);
        box-shadow: 0px 4px 54px 0px #00318C;
    }
    .dr-view:hover .dr{
        width: 145px;
        height: 145px;
        border: 5px solid rgba(225,225,225,0.62);
        background-color: #007FED;
        border: 3px solid #2A00FF;
        color: white;
    }
    .sj-img{
        width: 37px;
        height: 36px;
        margin-bottom: 11px;
        background: url("../assets/sj-icon.png") no-repeat;
        background-size: 37px;
    }
    .sj-view :hover .sj-img{
        background: url("../assets/sj.png") no-repeat;
        background-size: cover;
    }
    .content-view{
        width: 159px;
        height: 159px;
        border-radius: 50%;
        background-color: rgba(225,225,225,0.3);
        display: flex;

        align-items: center;
        justify-content: center;
        transition: 0.9s;
    }

    .sj-view:hover{
        width: 166px;
        height: 166px;
        transform: translate(-5px,-30px);
        background-color: rgba(225,225,225,0.62);
        box-shadow: 0px 4px 54px 0px #00318C;
    }
    .sj-view:hover .sj{
        width: 145px;
        height: 145px;
        border: 5px solid rgba(225,225,225,0.62);
        background-color: #007FED;
        border: 3px solid #2A00FF;
        color: white;
    }
.content{
    position: absolute;
    top: -100px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 100;
    padding: 0 103px 0 118px;
    box-sizing: border-box;
}
    .footer{
        background-color: transparent;
        z-index:100;
        height: 60px;
        line-height: 60px;
        text-align: center;
        color: #fff;
        position: absolute;
        width: 100%;
        font-size: 20px;
        z-index: 1000;
        /deep/ a{
            text-decoration: none;
            color: #fff;
        }
    }

</style>
